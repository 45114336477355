/**
 * @file 项目实践
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../../../components/vertical-ellipsis/style.css';

.paddle-application-card-group-wrap {
    height: 353px;
    margin-top: 40px;
    position: relative;
    z-index: 1;
}

.paddle-application-card-group {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.paddle-application-card {
    background-color: #fff;
    position: relative;
    transition: all 0.3s cubic-bezier(.645, .045, .355, 1);

    &:hover {
        box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, .05);
        z-index: 1;
    }

    &-grid {
        display: inline-block;
        vertical-align: top;
    }

    &-img {
        background-color: #ebebeb;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 160px;
        overflow: hidden;

        img {
            opacity: 0;
            width: 100%;
        }
    }

    &-contain {
        border: 1px solid #d8d8d8;
        border-top: 0;
        padding: 30px 40px 25px 40px;
        position: relative;
    }

    &-extra {
        position: absolute;
        right: 40px;
        top: 33px;
    }

    &-extra-tag {
        background: #eee;
        border-radius: 999999px;
        color: #666;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 22px;
        min-width: 50px;
        padding: 0 13px;
        text-align: center;
    }

    &-grid + &-grid &-contain {
        margin-left: -1px;
    }

    &-title {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        height: 30px;
        line-height: 28px;
        letter-spacing: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
    }

    &:hover &-title {
        color: @primary-color;
    }

    &-text {
        color: #666;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 22px;
        margin-top: 10px;
        position: relative;
        text-align: justify;
    }

    &:hover &-text-ellipsis {
        display: none;
    }

    &-text-inner {
        height: 56px;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
    }

    &:hover &-text-inner {
        //height: auto;
    }

    &-tag-group {
        color: #b0b0b0;
        font-size: 14px;
        letter-spacing: 0;
        margin-top: 20px;
        min-height: 21px;
    }

    & > a[href] &-tag-group {
        color: @primary-color;
    }
}
